import "./App.css"
import Expens from "./Components/Expense/Expens";

function App() {
  return (
    // <Card>
    <>
      <Expens />
      {/* <Expensechart/> */}
      </>
    //  </Card>
  );
}

export default App;
