import React from 'react';
import './Expensefilter.css';
import Data from '../Data/Data';

export default function Expensefilter({ selectedYear, setSelectedYear, expenseData, setExpenseData, newChartData, setNewChartData }) {
    const handleYearChange = (e) => {
        const year = e.target.value;
        setSelectedYear(year);
        const filteredData = filterData(year);
        setExpenseData(filteredData);
        // console.log(expenseData)
        // console.log(newChartData)
        const chartData = calculateNewChartData(filteredData);
        // console.log(chartData)
        setNewChartData(chartData);
    };

    const calculateNewChartData = (filteredData) => {
        const monthlyTotals = {};
        filteredData.forEach((item) => {
            const monthLabel = item.date.toLocaleDateString('en-US', { month: 'long' });
            // console.log(monthLabel)
            if (!monthlyTotals[monthLabel]) {
                monthlyTotals[monthLabel] = 0;
            }
            monthlyTotals[monthLabel] += item.price; 
        });
        const newChartData = Object.entries(monthlyTotals).map(([label, value]) => ({
            label,
            value,
        }));
        // console.log(newChartData)
        return newChartData;
    };

    const filterData = (year) => {
        return Data.filter((item) => item.date.getFullYear() === parseInt(year));
    };

    const handleShowAllExpense = (e) => {
        e.preventDefault();
        setExpenseData(Data);
    }

    return (
        <div className="expensefilter">
            <label htmlFor="expensefilter">Filter by Year:</label>
            <div className="rightexpese">
                <select id="expensefilter" value={selectedYear} onChange={handleYearChange}>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                </select>

                {/* <button className='showallbtn' onClick={handleShowAllExpense}>Show All Expense</button> */}
            </div>

        </div>
    );
}
