import React from 'react'
import './ExpenseDate.css'
import Card from "./../Card/Card"
export default function ExpenseDate(props) {
    const expense_date_year = props.date.getFullYear();
    const expense_date_month = props.date.toLocaleDateString('en-US', { month: 'long' });
    const expense_date_day = props.date.getDate();
    return (
        <>
            <div className="expense_date_day">{expense_date_day}</div>
            <div className="expense_date_month">{expense_date_month}</div>
            <div className="expense_date_year">{expense_date_year}</div>
        </>
    )
}
