const expense_data = [
    {
        id: '1',
        date: new Date('2022-01-05'),
        title: 'Groceries',
        price: 40
    },
    {
        id: '2',
        date: new Date('2023-08-10'),
        title: 'Gasoline',
        price: 30
    },
    {
        id: '3',
        date: new Date('2023-01-25'),
        title: 'Dinner with Friends',
        price: 60
    },
    {
        id: '4',
        date: new Date('2010-02-02'),
        title: 'Movie Tickets',
        price: 25
    },
    {
        id: '5',
        date: new Date('2021-03-15'),
        title: 'Internet Bill',
        price: 50
    },
    {
        id: '6',
        date: new Date('2020-04-20'),
        title: 'Books',
        price: 55
    },
    {
        id: '7',
        date: new Date('2019-05-12'),
        title: 'Health Insurance',
        price: 120
    },
    {
        id: '8',
        date: new Date('2018-06-28'),
        title: 'Electronics',
        price: 800
    },
    {
        id: '9',
        date: new Date('2017-07-03'),
        title: 'Gym Membership',
        price: 50
    },
    {
        id: '10',
        date: new Date('2016-09-22'),
        title: 'Coffee',
        price: 5
    },
    {
        id: '11',
        date: new Date('2023-10-20'),
        title: 'Car Maintenance',
        price: 100
    },
    {
        id: '12',
        date: new Date('2022-11-15'),
        title: 'Restaurant',
        price: 70
    },
    {
        id: '13',
        date: new Date('2015-12-08'),
        title: 'Mobile Phone',
        price: 300
    },
    {
        id: '14',
        date: new Date('2022-02-10'),
        title: 'Vacation',
        price: 500
    },
    {
        id: '15',
        date: new Date('2023-03-25'),
        title: 'Electricity Bill',
        price: 80
    },
    {
        id: '16',
        date: new Date('2021-04-05'),
        title: 'Clothing',
        price: 75
    },
    {
        id: '17',
        date: new Date('2019-08-12'),
        title: 'Furniture',
        price: 350
    },
    {
        id: '18',
        date: new Date('2018-09-18'),
        title: 'Phone Bill',
        price: 45
    },
    {
        id: '19',
        date: new Date('2020-10-23'),
        title: 'Groceries',
        price: 60
    },
    {
        id: '20',
        date: new Date('2022-05-15'),
        title: 'Travel',
        price: 200
    },
    {
        id: '21',
        date: new Date('2017-06-08'),
        title: 'Dental Checkup',
        price: 75
    },
    {
        id: '22',
        date: new Date('2023-07-30'),
        title: 'Home Repairs',
        price: 150
    },
    {
        id: '23',
        date: new Date('2019-08-11'),
        title: 'Pet Care',
        price: 35
    },
    {
        id: '24',
        date: new Date('2022-09-22'),
        title: 'Subscription',
        price: 10
    },
    {
        id: '25',
        date: new Date('2023-01-18'),
        title: 'Medical Expenses',
        price: 90
    },
    {
        id: '26',
        date: new Date('2022-11-02'),
        title: 'Car Insurance',
        price: 120
    },
    {
        id: '27',
        date: new Date('2023-03-14'),
        title: 'Home Insurance',
        price: 200
    },
    {
        id: '28',
        date: new Date('2019-04-30'),
        title: 'Car Repairs',
        price: 70
    },
    {
        id: '29',
        date: new Date('2021-12-12'),
        title: 'Gifts',
        price: 40
    },
    {
        id: '30',
        date: new Date('2018-07-07'),
        title: 'Eating Out',
        price: 45
    },
    {
        id: '31',
        date: new Date('2023-02-02'),
        title: 'Eating Out',
        price: 45
    },
    {
        id: '32',
        date: new Date('2020-04-30'),
        title: 'Car Repairs',
        price: 70
    },
    {
        id: '33',
        date: new Date('2020-12-12'),
        title: 'Gifts',
        price: 40
    },
    {
        id: '34',
        date: new Date('2020-07-07'),
        title: 'Eating Out',
        price: 45
    },
    {
        id: '35',
        date: new Date('2020-02-02'),
        title: 'Eating Out',
        price: 45
    },
];

export default expense_data;
