import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { BarChart } from '../Chart/BarChart';
import PieChart from '../Chart/PieChart'
Chart.register(CategoryScale);

export default function Expensechart({ newChartData, isBarChart}) {
  
  const [chartData, setChartData] = useState({
    labels: newChartData.map((data) => data.label),
    datasets: [
      {
        label: 'Expenses', 
        data: newChartData.map((data) => data.value),
        backgroundColor: [
          'rgba(75,192,192,1)',
          '#ecf0f1', 
          '#50AF95',
          '#f3ba2f',
          '#2a71d0',
        ],
        borderColor: 'black',
        borderWidth: 2,
      },
    ],
  });

  useEffect(() => {
    setChartData({
      ...chartData,
      labels: newChartData.map((data) => data.label),
      datasets: [
        {
          ...chartData.datasets[0],
          data: newChartData.map((data) => data.value),
        },
      ],
    });
  }, [newChartData]);

  return isBarChart ? <BarChart chartData={chartData}/> : <PieChart chartData={chartData}/>
}
