import React from 'react'
import './ExpenseInput.css'
import { useState } from "react";
import Expensechart from '../Expensechart/Expensechart';

export default function ExpenseInput(props) {
    const [userInput, setUserInput] = useState({
        expenseTitle: ' ',
        expenseAmount: ' ',
        expenseDate: ' '
    })

    const handleChangeTitle = (e) => {
        e.preventDefault();
        setUserInput(
            {
                expenseTitle: e.target.value
            }
        )
    }

    const handleChangePrice = (e) => {
        e.preventDefault();
        setUserInput(
            {
                ...userInput,
                expenseAmount: e.target.value
            }
        )
    }

    const handleChangeDate = (e) => {
        e.preventDefault();
        setUserInput(
            {
                ...userInput,
                expenseDate: e.target.value
            }
        )
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // console.log(userInput)
        if (userInput.expenseTitle !== ' ' && userInput.expenseAmount > 0 && userInput.expenseDate != ' ') {
            const newData = {
                id: Math.random().toString(),
                date: new Date(userInput.expenseDate),
                price: userInput.expenseAmount,
                title: userInput.expenseTitle
            }

            props.setExpenseData([newData, ...props.expenseData]);
            setUserInput({
                expenseTitle: ' ',
                expenseAmount: ' ',
                expenseDate: ' '
            })
        }else{
            alert('Enter Correct Detail')
        }
    }



    return (
        <>
        {/* <h1 className='left'>Add Your Expense</h1> */}
     
        <div className='expense_input'>
            
            <form className="expense_form" onSubmit={handleFormSubmit}>
                {/* <h1>Add Your Expense Here</h1> */}
                <h1>Expense Title</h1>
                <input
                    type="text"
                    className='input_expense_title'
                    placeholder='enter title'
                    value={userInput.expenseTitle}
                    // autoFocus
                    onChange={handleChangeTitle}
                />

                <h1>Expense Amount</h1>
                <input
                    type="number"
                    className='input_expense_price'
                    placeholder='enter your amount'
                    value={userInput.expenseAmount}
                    onChange={handleChangePrice}
                />

                <h1>Expense Date</h1>
                <input
                    type='date'
                    className='input_expense_date'
                    value={userInput.expenseDate}
                    onChange={handleChangeDate}
                />

                <button className="add_expense" >Add Expense</button>

            </form>
            {/* <Expensechart/> */}
        </div>
        </>
    )
}



/* 
const handleChangeTitle = (e) => {
    e.preventDefault();
    setUserInput((prevState) => ({
        ...prevState,
        expenseTitle: e.target.value
    }));
}

const handleChangePrice = (e) => {
    e.preventDefault();
    setUserInput((prevState) => ({
        ...prevState,
        expenseAmount: e.target.value
    }));
}

const handleChangeDate = (e) => {
    e.preventDefault();
    setUserInput((prevState) => ({
        ...prevState,
        expenseDate: e.target.value
    }));
}



*/