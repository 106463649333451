import React, { useState } from 'react';
import ExpenseItem from "../ExpenseItem/ExpenseItem";
import ExpenseInput from "../ExpenseInput/ExpenseInput";
import Expensefilter from '../Expensefilter/Expensefilter';
import Card from '../Card/Card';
import Expensechart from '../Expensechart/Expensechart';
import chartdata from '../Data/ChartData';

export default function Expens() {
    const [expenseData, setExpenseData] = useState([]);
    const [selectedYear, setSelectedYear] = useState(2020);
    const [newChartData, setNewChartData] = useState(chartdata);
    // console.log(newChartData)
    
    const handleExpenseEdit = (id) => {
        let newExpenseTitle = prompt("Enter Your New Expense Title");
        if (newExpenseTitle !== null) {
            setExpenseData((prevExpenseData) => {
                return prevExpenseData.map((expense) => {
                    if (expense.id === id) {
                        return { ...expense, title: newExpenseTitle };
                    }
                    return expense;
                });
            });
        }
    };


    const handleExpenseDelete = (id) => {
        let index;
        for (let i = 0; i < expenseData.length; i++) {
            if (expenseData[i].id === id) {
                index = i;
            }
        }
        const updatedExpenseData = [...expenseData];
        updatedExpenseData.splice(index, 1);
        setExpenseData(updatedExpenseData);
    }



    return (
        <div>
            <Card>
                <ExpenseInput expenseData={expenseData} setExpenseData={setExpenseData} />
                <div className="expenseChartCircle">
                <Expensechart newChartData={newChartData} isBarChart={false}/>
                </div>
            </Card>
            <hr />
            <div className="seccard">
                <Expensefilter selectedYear={selectedYear} setSelectedYear={setSelectedYear} setExpenseData={setExpenseData} expenseData={expenseData} newChartData={newChartData} setNewChartData={setNewChartData}/>
                <div className="expenseChart">
                    <Expensechart newChartData={newChartData} isBarChart={true}/>
                </div>
                {
                    expenseData.length > 0 ?
                        expenseData.map((item) => (
                            <ExpenseItem
                                date={item.date}
                                title={item.title}
                                price={item.price}
                                key={item.id}
                                id={item.id}
                                handleExpenseEdit={handleExpenseEdit}
                                handleExpenseDelete={handleExpenseDelete}
                            />
                        )) : <div className="nodata">
                            <h1>No Data Found!!!</h1>
                        </div>
                }
            </div>
        </div>
    );
}
