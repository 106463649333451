let chartData = [
    {
        label: 'Jan',
        value: 100,
    },
    {
        label: 'Feb',
        value: 200,
    },
    {
        label: 'March',
        value: 800,
    },
    // {
    //     label: 'April',
    //     value: 0,
    // },
    // {
    //     label: 'May',
    //     value: 0,
    // },
    // {
    //     label: 'June',
    //     value: 0,
    // },
    // {
    //     label: 'July',
    //     value: 0,
    // },
    // {
    //     label: 'Aug',
    //     value: 0,
    // },
    // {
    //     label: 'Sep',
    //     value: 0,
    // },
    // {
    //     label: 'Oct',
    //     value: 0,
    // },
    // {
    //     label: 'Nov',
    //     value: 0,
    // },
    // {
    //     label: 'Dec',
    //     value: 0,
    // },
    // {
    //     id: 1,
    //     year: 2016,
    //     userGain: 80000,
    //     userLost: 823
    //   },
    //   {
    //     id: 2,
    //     year: 2017,
    //     userGain: 45677,
    //     userLost: 345
    //   },
    //   {
    //     id: 3,
    //     year: 2018,
    //     userGain: 78888,
    //     userLost: 555
    //   },
    //   {
    //     id: 4,
    //     year: 2019,
    //     userGain: 90000,
    //     userLost: 4555
    //   },
    //   {
    //     id: 5,
    //     year: 2020,
    //     userGain: 4300,
    //     userLost: 234
    //   }
]
export default chartData;