
import React from 'react';
import './ExpenseItem.css';
import ExpenseDate from '../ExpenseDate/ExpenseDate';

export default function ExpenseItem(props) {

    const {handleExpenseDelete, handleExpenseEdit} = props;
    return (
        <>
            <div className='expense_item'>
                <div className="expense_date ">
                    <ExpenseDate date={props.date} />
                </div>
               
                    <div className="expense_title">{props.title}</div>
                    <div>
                    <div className="expense_price">${props.price}</div>
                    <div className="expenseicon">
                        <i className="fa-solid fa-pen edit" onClick={()=>handleExpenseEdit(props.id)}></i>
                        <i className="fa-solid fa-trash delete" onClick={()=>handleExpenseDelete(props.id)}></i>
                    </div>
                </div>
            </div>

        </>
    );
}
